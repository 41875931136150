<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>
			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="name" label="标签名称"> </el-table-column>
			<el-table-column prop="name_en" label="标签英文名称"> </el-table-column>
			<el-table-column label="语言">
				<template slot-scope="scope">
					<span v-if="scope.row.language == 1">简体中文</span>
					<span v-if="scope.row.language == 2">英语</span>
					<span v-if="scope.row.language == 3">日语</span>
					<span v-if="scope.row.language == 4">繁体中文</span>
				</template>
			</el-table-column>
			<el-table-column prop="pic" label="图片链接"> </el-table-column>
			<el-table-column prop="notes" label="备注" show-overflow-tooltip>
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="toEdit(scope.row)"
						>编辑</span
					>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)"
						>删除</span
					>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { delLabel } from "@/api/article";
export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		toEdit(row) {
			this.$router.push({
				path: "/message/addLabel",
				query: { row: row, type: "edit" },
			});
		},
		toDelete(row) {
			this.$confirm("此操作将删除该条标签, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					delLabel({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
