<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title">
				<span>标签新增</span>
			</div>
			<div class="add_main hidden_scroll">
				<div class="margin_top_20">
					<el-form
						label-position="right"
						:rules="rule"
						ref="formData"
						label-width="120px"
						:model="formData"
					>
						<el-form-item label="标签名称：" prop="name">
							<el-input v-model="formData.name" class="custom_input_350">
							</el-input>
						</el-form-item>
						<el-form-item label="标签英文名称：" prop="name_en">
							<el-input v-model="formData.name_en" class="custom_input_350">
							</el-input>
						</el-form-item>
						<el-form-item label="备注：">
							<el-input
								v-model="formData.notes"
								class="custom_input_350"
								type="textarea"
								:rows="4"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="图片：">
							<el-upload
								class="upload-demo"
								action=""
								:show-file-list="false"
								:http-request="uploadImage"
							>
								<img v-if="formData.pic" :src="formData.pic" class="logo" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="语言" prop="language">
							<el-select v-model="formData.language" placeholder="请选择语言">
								<el-option label="简体中文" value="1"> </el-option>
								<el-option label="英语" value="2"> </el-option>
								<!-- <el-option label="日语" value="3">
                                </el-option>
                                <el-option label="繁体中文" value="4">
                                </el-option> -->
							</el-select>
						</el-form-item>
						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="submit('formData')"
									>保存</el-button
								>
								<el-button
									class="custom_btn_width_100"
									@click="$router.push({ path: '/message/label' })"
									>返回</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import { validateNull } from "@/utils/validate";
import { addLabel, editLabel } from "@/api/article";
import { uploadImage } from "@/api/user";
export default {
	name: "",
	data() {
		return {
			rule: {
				name: [{ validator: validateNull, trigger: "blur", required: true }],
				// notes: [{ validator: validateNull, trigger: "blur", required: true }],
				language: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				// pic: [{ validator: validateNull, trigger: "change", required: true }],
			},
			value: "",
			formData: {
				name: "",
				name_en: "",
				notes: "",
				pic: "",
				language: "",
				id: "",
			},
			placeholders: {
				province: "请选择省份",
				city: "请选择市",
				area: "请选择区或县",
			},
		};
	},
	created() {
		if (!this.$route.query.row) {
			this.clearFormAdd();
		} else {
			let row = this.$route.query.row;
			this.formData.name = row.name;
			this.formData.name_en = row.name_en;
			this.formData.notes = row.notes;
			this.formData.pic = row.pic;
			this.formData.language = row.language.toString();
			this.formData.id = row.id;
		}
	},
	methods: {
		// 上传图片
		uploadImage(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.formData.pic = res.data.url;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch(function (error) {});
		},
		// detailsactivity() {
		//     const that = this;
		//     detailsactivity({ d: that.$route.query.id }).then((res) => {
		//         if (res.code == 200) {
		//             that.formData = res.data
		//             that.formData.type = that.formData.type.toString();
		//             that.formData.language = that.formData.language.toString()
		//              that.formData.is_free =  that.formData.is_free.toString()
		//              that.formData.is_show =  that.formData.is_show.toString()
		//         } else {
		//             that.$message.error(res.message);
		//         }
		//     }).catch((error) => {
		//         that.$message.error(error.message);
		//     });
		// },
		submit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const that = this;
					if (this.$route.query.type) {
						editLabel(that.formData)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/message/label" });
								} else {
								}
							})
							.catch((error) => {});
					} else {
						addLabel(that.formData)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/message/label" });
								} else {
								}
							})
							.catch((error) => {});
					}
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		clearFormAdd() {
			for (let key in this.formData) {
				this.formData[key] = "";
			}
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}
</style>
<style>
.distpicker-address-wrapper select {
	width: 255px !important;
	padding: 0px 10px !important;
	height: 35px !important;
	font-size: 18px !important;
	line-height: 35px !important;
}
</style>
