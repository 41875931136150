<template>
    <div class="other_page_r">
        <el-card class="card_box">
            <div slot="header" class="card_title">
                <span>用户管理/类别管理</span>
            </div>
            <div>
                <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
                    <el-form-item label="类别名称">
                        <el-input class="custom_input_170" v-model="searchInfo.keywords" placeholder=""></el-input>
                    </el-form-item>
                  
                    <el-form-item>
                        <el-button type="primary" @click="searchBtn">查询</el-button>
                        <el-button type="primary" @click="reset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_content_end custom_btn">
                <el-button @click="$refs.addCategory.dialog=true;title='新增类别'">新增类别</el-button>
            </div>
            <div class="table_box">
                <tablec :tableData="tableData"></tablec>
            </div>
            <div class="flex justify_content_sb aligin_items_c table_footer custom_btn">
                <el-button>批量删除</el-button>
                <div>

                    <pagination
                    v-show="total > 0"
                    :total="total"
                    :page.sync="searchInfo.page"
                    :limit.sync="searchInfo.size"
                    @pagination="getDataList"
                  />

                </div>
            </div>
        </el-card>
        <addCategory ref="addCategory" :title="title"></addCategory>
    </div>
</template>

<script>
    import addCategory from "./addCategory.vue"
    import tablec from "./tablec.vue"
    import { getCategoryList } from "@/api/article";
    import { searchMixin } from "@/utils/searchMixin.js";
    export default {
        mixins: [searchMixin],
        components: {  addCategory,tablec },
        name: '',
        data() {
            return {
                title:"类别新增",
                tableData:[],
                total: Number,
                searchInfo: {
                    keywords: "",
                    type: 2,
                    page: 1,
                    size: 10,
                },

            }
        },
        created() {
            this.getDataList()
        },
        methods: {
            searchBtn(){
                this.searchInfo.page=1
                this.getDataList()
            },
       getDataList() {
        getCategoryList(this.searchInfo).then((res) => {
               if (res.code == 200) {
                   this.tableData = res.data.data
                   this.total = res.data.total;
               } else {

                   this.tableData = []
                   this.total = 0;
                   
               }
               this.loading = false;
           }).catch((error) => {
               this.$message.error(error.message);
           });
       }
   }

    }
</script>

<style scoped>

</style>