<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>信息审核</span>
			</div>
			<div>
				<el-form :inline="true" :model="searchInfo" class="demo-form-inline">
					<el-form-item label="资讯信息">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.title"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="分类">
						<el-select
							class="custom_input_170"
							v-model="searchInfo.category_id"
							placeholder=""
						>
							<el-option
								:label="item.name"
								:value="item.id"
								v-for="(item, index) in categoryList"
								:key="index"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="审核状态">
						<el-select
							class="custom_input_170"
							v-model="searchInfo.examine_type"
							placeholder=""
						>
							<el-option label="通过" value="1"></el-option>
							<el-option label="审核中" value="2"></el-option>
							<el-option label="拒绝" value="3"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
					</el-form-item>
				</el-form>
			</div>

			<div class="table_box">
				<tablec :tableData="tableData"></tablec>
			</div>
			<div
				class="flex justify_content_e aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import { authColumnList } from "@/api/examine";
import { getInformationList, getCategoryList } from "@/api/article";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	mixins: [searchMixin],
	components: { tablec },
	name: "",
	data() {
		return {
			tableData: [],
			total: Number,
			categoryList: [],
			searchInfo: {
				title: "",
				category_id: "",
				examine_type: "",
				page: 1,
				size: 10,
				type: 5,
				source_id: "1024",
			},
		};
	},
	created() {
		let _obj = JSON.parse(sessionStorage.getItem("messageListKey_examine"));
		if (_obj) {
			Object.keys(this.searchInfo).forEach((key) => {
				this.searchInfo[key] = _obj[key] || this.searchInfo[key];
			});
		}
		this.getDataList();
		this.getCategoryListFun();
	},
	methods: {
		getCategoryListFun() {
			getCategoryList({})
				.then((res) => {
					if (res.code == 200) {
						this.categoryList = res.data.data;
					} else {
						this.categoryList = [];
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},
		getDataList() {
			let _data = Object.assign(this.searchInfo, {});
			sessionStorage.setItem("messageListKey_examine", JSON.stringify(_data));
			const that = this;
			// this.searchInfo.user_type = 4;
			this.searchInfo.type = 5;
			this.searchInfo.source_id = "1024";
			getInformationList(this.searchInfo)
				.then((res) => {
					if (res.code == 200) {
						that.tableData = res.data.data;
						that.total = res.data.total;
					} else {
						that.tableData = [];
						that.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped></style>
