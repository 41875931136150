import request from "@/utils/request";

// 首页数据
export function getHomeData(obj) {
	return request({
		url: "/admin/homeData",
		method: "post",
		data: obj,
	});
}
// 资讯列表
export function getInformationList(obj) {
	return request({
		url: "/admin/information/getInformationList",
		method: "post",
		data: obj,
	});
}
// 是否屏蔽
export function disInformation(obj) {
	return request({
		url: "/admin/information/disInformation",
		method: "post",
		data: obj,
	});
}
// tg推送
export function addTgPush(obj) {
	return request({
		url: "/admin/push/addTgPush",
		method: "post",
		data: obj,
	});
}

//   是否轮播
export function informationHome(obj) {
	return request({
		url: "/admin/information/informationHome",
		method: "post",
		data: obj,
	});
}
// 专题
export function specialList(obj) {
	return request({
		url: "/admin/special/lists",
		method: "post",
		data: obj,
	});
}
//   新增专题

export function addspecial(obj) {
	return request({
		url: "/admin/special/create",
		method: "post",
		data: obj,
	});
}
//   修改专题
export function updatespecial(obj) {
	return request({
		url: "/admin/special/update",
		method: "post",
		data: obj,
	});
}
//   删除专题
export function destroyspecial(obj) {
	return request({
		url: "/admin/special/destroy",
		method: "post",
		data: obj,
	});
}
//   类别
export function getCategoryList(obj) {
	return request({
		url: "/admin/information/getCategoryList",
		method: "post",
		data: obj,
	});
}
//   新增类别

export function addCategory(obj) {
	return request({
		url: "/admin/information/addCategory",
		method: "post",
		data: obj,
	});
}
//   删除类别
export function delCategory(obj) {
	return request({
		url: "/admin/information/delCategory",
		method: "post",
		data: obj,
	});
}
//   添加资讯
export function addInformation(obj) {
	return request({
		url: "/admin/information/addInformation",
		method: "post",
		data: obj,
	});
}
//   标签列表
export function getLabelList(obj) {
	return request({
		url: "/admin/information/getLabelList",
		method: "post",
		data: obj,
	});
}
//   新增标签
export function addLabel(obj) {
	return request({
		url: "/admin/information/addLabel",
		method: "post",
		data: obj,
	});
}
//   修改标签
export function editLabel(obj) {
	return request({
		url: "/admin/information/editLabel",
		method: "post",
		data: obj,
	});
}
//   删除标签
export function delLabel(obj) {
	return request({
		url: "/admin/information/delLabel",
		method: "post",
		data: obj,
	});
}
export function recoverInformation(obj) {
	return request({
		url: "/admin/information/recoverInformation",
		method: "post",
		data: obj,
	});
}
//   获取资讯详情
export function getInformationDetail(obj) {
	return request({
		url: "/admin/information/getInformationDetail",
		method: "post",
		data: obj,
	});
}
//   删除资讯

export function delInformation(obj) {
	return request({
		url: "/admin/information/delInformation",
		method: "post",
		data: obj,
	});
}
//   公告管理
export function noticeLists(obj) {
	return request({
		url: "/admin/notice/lists",
		method: "post",
		data: obj,
	});
}
//   新增公告
export function createnotice(obj) {
	return request({
		url: "/admin/notice/create",
		method: "post",
		data: obj,
	});
}
export function updatenotice(obj) {
	return request({
		url: "/admin/notice/update",
		method: "post",
		data: obj,
	});
}
//   获取公告详情
export function detailsnotice(obj) {
	return request({
		url: "/admin/notice/details",
		method: "get",
		params: obj,
	});
}
//   删除公告

export function destroynotice(obj) {
	return request({
		url: "/admin/notice/destroy",
		method: "post",
		data: obj,
	});
}
//   活动列表
export function activitylists(obj) {
	return request({
		url: "/admin/activity/lists",
		method: "post",
		data: obj,
	});
}
//   活动列表
export function mainLists(obj) {
	return request({
		url: "/admin/activity/mainLists2",
		method: "post",
		data: obj,
	});
}
//   活动列表
export function ticketlists(obj) {
	return request({
		url: "/admin/activity/ticketlists",
		method: "post",
		data: obj,
	});
}
//   编辑订单
export function orderUpdate(obj) {
	return request({
		url: "/admin/activity/orderUpdate",
		method: "post",
		data: obj,
	});
}
//   添加活动
export function createactivity(obj) {
	return request({
		url: "/admin/activity/create",
		method: "post",
		data: obj,
	});
}
//   编辑活动
export function updateactivity(obj) {
	return request({
		url: "/admin/activity/update",
		method: "post",
		data: obj,
	});
}

//   bot列表
export function botLists(obj) {
	return request({
		url: "/admin/push/botList",
		method: "post",
		data: obj,
	});
}

//   bot更新
export function updateBotDetai(obj) {
	return request({
		url: "/admin/push/updateBotDetai",
		method: "post",
		data: obj,
	});
}

//   bot详情
export function botDetail(obj) {
	return request({
		url: "/admin/push/botDetail",
		method: "post",
		data: obj,
	});
}
//   bot推送
export function pushBot(obj) {
	return request({
		url: "/admin/push/pushBot",
		method: "post",
		data: obj,
	});
}
//   删除活动
export function destroyactivity(obj) {
	return request({
		url: "/admin/activity/destroy",
		method: "post",
		data: obj,
	});
}

//   推荐活动
export function recommendactivity(obj) {
	return request({
		url: "/admin/activity/recommend",
		method: "post",
		data: obj,
	});
}
//   活动详情
export function detailsactivity(obj) {
	return request({
		url: "/admin/activity/details",
		method: "post",
		params: obj,
	});
}
//   side
export function sideevent(obj) {
	return request({
		url: "/admin/activity/sideevent",
		method: "post",
		params: obj,
	});
}

//   side
export function addOtherSideEvent(obj) {
	return request({
		url: "/admin/activity/addOtherSideEvent",
		method: "post",
		data: obj,
	});
}
export function otherSideEventList(obj) {
	return request({
		url: "/admin/activity/otherSideEventList",
		method: "post",
		data: obj,
	});
}
