<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			id="exportTab"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			height="60vh"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>
			<el-table-column prop="id" label="id" width="100px"> </el-table-column>
			<el-table-column prop="title" label="工具名称" show-overflow-tooltip>
			</el-table-column>
			<el-table-column
				prop="title_en"
				label="工具英文名称"
				show-overflow-tooltip
			>
			</el-table-column>

			<el-table-column prop="category_name" label="分类" width="120px">
			</el-table-column>
			<el-table-column prop="release_time" label="发布时间" width="150px">
			</el-table-column>
			<el-table-column label="预览" width="160px">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="showPreView(scope.row)">
						预览
					</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200px">
				<template slot-scope="scope">
					<span
						class="pointer margin_left_10"
						@click="disInformation(scope.row)"
					>
						{{ scope.row.status == 1 ? "屏蔽" : "取消屏蔽" }}
					</span>
					<span class="pointer margin_left_10" @click="toEdit(scope.row)">
						修改
					</span>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)">
						删除
					</span>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import {
	disInformation,
	delInformation,
	informationHome,
	addInformation,
} from "@/api/article";
import { addPush } from "@/api/user";
import clipboard from "clipboard";

export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		disInformation(row) {
			disInformation({ id: row.id }).then((res) => {
				if (res.code == 200) {
					console.log(this.$parent);
					this.$parent.$parent.getDataList();
				}
			});
		},
		informationHome(row) {
			informationHome({ id: row.id }).then((res) => {
				if (res.code == 200) {
					console.log(this.$parent);
					this.$parent.$parent.getDataList();
				}
			});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		toDetail(row) {
			this.$router.push({
				path: "/message/addtools",
				query: { id: row.id, type: "detail" },
			});
		},
		toEdit(row) {
			this.$router.push({
				path: "/message/addtools",
				query: { id: row.id, type: "edit" },
			});
		},

		showPreView(row) {
			let url = row.url;
			window.open(url, "预览");
		},
		toDelete(row) {
			this.$confirm("此操作将删除该工具, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					delInformation({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
	},
};
</script>

<style scoped></style>
