<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title">
				<span>工具新增</span>
			</div>
			<div class="add_main hidden_scroll">
				<div class="margin_top_10">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<el-form-item label="资讯分类" prop="category_id">
							<el-select
								v-model="formData.category_id"
								placeholder="请选择资讯分类"
								:disabled="$route.query.type == 'detail'"
							>
								<el-option
									:label="item.name"
									:value="item.id"
									v-for="(item, index) in categoryList"
									:key="index"
								>
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="标题" prop="title">
							<el-input
								v-model="formData.title"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="英文标题" prop="title_en">
							<el-input
								v-model="formData.title_en"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="副标题" prop="illustrate">
							<el-input
								v-model="formData.illustrate"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="英文副标题" prop="illustrate_en">
							<el-input
								v-model="formData.illustrate_en"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="工具链接" prop="url">
							<el-input
								v-model="formData.url"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>

						<el-form-item label="工具图片" prop="pic">
							<img
								style="width: 80%"
								v-if="formData.pic"
								:src="formData.pic"
								@click="uploadPicture(formData.pic)"
							/>
							<div v-else>
								<div
									class="el-upload__text"
									@click="uploadPicture(formData.pic)"
								>
									<img
										style="width: 100px"
										src="../../../assets/images/uploadImg.png"
										alt=""
									/>
									<br /><em>点击加号上传</em></div
								>
							</div>
							<!-- 剪裁组件弹窗 -->
							<el-dialog
								title="裁切图片"
								:visible.sync="cropperModel"
								width="1300px"
								center
								:append-to-body="true"
							>
								<template v-if="cropperModel">
									<cropper-image-new
										:Name="cropperName"
										:type="scale"
										@uploadImgSuccess="handleUploadSuccess"
										ref="child"
									>
									</cropper-image-new
								></template>
							</el-dialog>
						</el-form-item>

						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="submit('formData')"
									v-if="$route.query.type != 'detail'"
									>保存</el-button
								>
								<el-button class="custom_btn_width_100" @click="back"
									>返回</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import { myMixin } from "@/utils/mixin.js";
import {
	getCategoryList,
	addInformation,
	getInformationDetail,
} from "@/api/article";
import { uploadImage, getUsersList } from "@/api/user";
import { validateNull } from "@/utils/validate";

export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			props: { multiple: false },
			rule: {
				category_id: [
					{ validator: validateNull, trigger: "change", required: true },
				],

				title: [{ validator: validateNull, trigger: "blur", required: true }],
				illustrate: [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				pic: [{ validator: validateNull, trigger: "change", required: true }],

				url: [{ validator: validateNull, trigger: "change", required: true }],
			},

			value: "",
			formData: {
				author_id: "1775",
				category_id: "",
				special_id: "",
				label_id: 0,
				ticket_id: [],
				title: "",
				title_en: "",
				illustrate: "",
				illustrate_en: "",
				url: "",
				language: "",
				pic: "",
				content: "",
				status: 1,
				type: "6",
				is_home: "",
				is_recommend: "",
				is_top: "",
				score: "",
				is_push: "",
			},
			// 专题id
			specialId: "",
			// 资讯分类
			categoryList: [],
			// 用户列表
			userList: [],
			// 标签列表
			labelList: [],
			// 资讯详情
			formationDetail: {},
			// 专题数据
			specialList: [],
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
			scale: "0",
		};
	},
	watch: {
		"$route.query.id": [
			function handler1() {
				if (!this.$route.query.id) {
					this.clearFormAdd();
				} else {
					this.getInformationDetail();
				}
			},
		],
	},
	created() {
		this.getCategoryListFun();
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.getInformationDetail();
		}
	},
	methods: {
		//封面设置
		uploadPicture(name) {
			if (this.$route.query.type == "detail") {
				// 详情不支持编辑图片
			} else {
				this.cropperName = name;
				this.type = "6";
				this.cropperModel = true;
			}
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			this.formData.pic = data.url;
		},
		// 资讯分类列表
		getCategoryListFun() {
			const that = this;
			getCategoryList({ size: 100, type: 6 })
				.then((res) => {
					if (res.code == 200) {
						that.categoryList = res.data.data;
						that.categoryList.forEach((item) => {
							item.id = item.id.toString();
						});
					} else {
						that.categoryList = [];
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},

		// 新增资讯提交
		submit(formName) {
			let ticket_id = "";
			let formData = this.formData;
			formData.ticket_id = ticket_id;
			if (formData.title && formData.title.length > 500) {
				this.$message.error("标题过长，请输入500以内内容");
				return;
			} else if (formData.illustrate && formData.illustrate.length > 500) {
				this.$message.error("副标题过长，请输入500以内内容");
				return;
			} else if (formData.pic && formData.pic.length > 1000) {
				this.$message.error("图片链接过长，请输入1000以内内容");
				return;
			} else if (formData.url && formData.url.length > 500) {
				this.$message.error("原文链接过长，请输入500以内内容");
				return;
			}
			const now = new Date();
			const localDate = new Date(now);
			// formData.release_time = localDate.toLocaleString();
			formData.author_id = "1775";
			formData.type = "6";
			this.$refs[formName].validate((valid) => {
				const that = this;
				if (valid) {
					that.formData.special_id = that.formData.special_id
						? that.formData.special_id[that.formData.special_id.length - 1]
						: that.formData.special_id;
					addInformation(formData)
						.then((res) => {
							if (res.code == 200) {
								that.$router.push({ path: "/message/tools" });
							} else {
							}
						})
						.catch((error) => {});
				} else {
					that.$message.error("缺少必填参数，请根据提示填写！");
					return false;
				}
			});
		},

		// 获取资讯详情
		getInformationDetail() {
			const that = this;
			getInformationDetail({ id: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						for (let key in that.formData) {
							that.formData[key] = res.data[key];
						}
						that.handletypeChange(that.formData.type);
						that.formData["id"] = res.data.id;
						that.formData.status = that.formData.status.toString();
						console.log(that.formData.status);
						that.formData.language = that.formData.language
							? that.formData.language.toString()
							: that.formData.language;
						that.formData.category_id = that.formData.category_id
							? that.formData.category_id.toString()
							: that.formData.category_id;
						that.formData.type = that.formData.type
							? that.formData.type.toString()
							: that.formData.type;
						that.formData.is_home = that.formData.is_home
							? that.formData.is_home.toString()
							: that.formData.is_home;
						that.formData.is_recommend = that.formData.is_recommend
							? that.formData.is_recommend.toString()
							: that.formData.is_recommend;
						that.formData.is_top = that.formData.is_top
							? that.formData.is_top.toString()
							: that.formData.is_top;
						that.formData.label_id = that.formData.label_id
							? that.formData.label_id.toString()
							: that.formData.label_id;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		back() {
			this.$router.push({ path: "/message/tools" });
		},
		clearFormAdd() {
			for (let key in this.formData) {
				this.formData[key] = "";
			}
		}, // 分类选择回调
		handletypeChange(e) {
			console.log(e);
			if (e == 2) {
				this.rule.pic = [{ trigger: "change", required: false }];
			} else {
				this.rule.pic = [
					{ validator: validateNull, trigger: "change", required: true },
				];
			}
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}
</style>
<style>
.ql-snow .ql-picker {
	line-height: 24px;
}
.ql-container {
	height: 400px;
	overflow: auto;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
	content: "黑体";
	font-family: "SimHei";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Microsoft-YaHei"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Microsoft-YaHei"]::before {
	content: "微软雅黑";
	font-family: "Microsoft YaHei";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
	content: "楷体";
	font-family: "KaiTi";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
	content: "仿宋";
	font-family: "FangSong";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
	content: "Arial";
	font-family: "Arial";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Times-New-Roman"]::before {
	content: "Times New Roman";
	font-family: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
	content: "sans-serif";
	font-family: "sans-serif";
}

.ql-font-SimSun {
	font-family: "SimSun";
}

.ql-font-SimHei {
	font-family: "SimHei";
}

.ql-font-Microsoft-YaHei {
	font-family: "Microsoft YaHei";
}

.ql-font-KaiTi {
	font-family: "KaiTi";
}

.ql-font-FangSong {
	font-family: "FangSong";
}

.ql-font-Arial {
	font-family: "Arial";
}

.ql-font-Times-New-Roman {
	font-family: "Times New Roman";
}

.ql-font-sans-serif {
	font-family: "sans-serif";
}

/* 字号设置 */
/* 默认字号 */
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: "14字号";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
	content: "14字号" !important;
	font-size: 14px;
}

.ql-size-14px {
	font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
	content: "16字号";
	font-size: 16px;
}

.ql-size-16px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
	content: "18字号";
	font-size: 16px;
}

.ql-size-18px {
	font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
	content: "20字号";
	font-size: 16px;
}

.ql-size-20px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
	content: "22字号";
	font-size: 16px;
}

.ql-size-22px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
	content: "26字号";
	font-size: 16px;
}

.ql-size-26px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
	content: "28字号";
	font-size: 16px;
}

.ql-size-28px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
	content: "30字号";
	font-size: 16px;
}

.ql-size-30px {
	font-size: 16px;
}
</style>
<style>
.ql-editor ul li {
	font-size: 14px;
	margin-bottom: 20px;
	font-weight: 400;
	line-height: 21px;
}
.ql-editor ul li::before {
	font-size: 20px;
	vertical-align: middle;
	font-weight: 400;
}

.ql-editor p {
	text-align: justify;
	margin-top: 20px;
	font-size: 18px;
}

.ql-editor p img,
.ql-editor p span img {
	margin-top: 10px;
	margin-bottom: 10px;
}
.ql-editor p p,
.ql-editor p a,
.ql-editor p em,
.ql-editor blockquote span,
.ql-editor p blockquote span,
.ql-editor p span {
	font-size: 18px;
	font-weight: 400;
	line-height: 36px;
}
.ql-editor h1 *,
.ql-editor h1 {
	font-size: 26px !important;
	font-weight: bold !important;
	margin-top: 40px !important;
	/* height: 60px; */
	margin-bottom: 5px;
	display: flex;
}
.ql-editor h2 *,
.ql-editor h2 {
	font-size: 22px !important;
	font-weight: bold !important;
	margin-top: 30px !important;
	/* height: 60px; */
	margin-bottom: 5px;
	display: flex;
}
.ql-editor h3 *,
.ql-editor h3 {
	font-size: 20px !important;
	font-weight: bold !important;
	margin-top: 25px !important;
	/* height: 60px; */
	margin-bottom: 5px;
	display: flex;
}
</style>
