<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="title" label="标题" width="120"> </el-table-column>
			<el-table-column
				prop="desc"
				label="描述"
				width="120"
				show-overflow-tooltip
			>
			</el-table-column>
			<el-table-column label="是否显示" width="120">
				<template slot-scope="scope">
					{{ scope.row.status == 1 ? "是" : "否" }}
				</template>
			</el-table-column>
			<el-table-column
				prop="create_date"
				label="创建时间"
				show-overflow-tooltip
			>
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span
						class="pointer margin_left_10"
						@click="disInformation(scope.row)"
					>
						{{ scope.row.status == 1 ? "屏蔽" : "取消屏蔽" }}
					</span>
					<span class="pointer margin_left_10" @click="toEdit(scope.row)"
						>编辑</span
					>
					<span class="pointer margin_left_10" @click="toDetail(scope.row)"
						>详情</span
					>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)"
						>删除</span
					>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { destroynotice } from "@/api/article";
export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		toDetail(row) {
			this.$router.push({
				path: "/message/addannouncement",
				query: { id: row.id, type: "detail" },
			});
		},
		toEdit(row) {
			this.$router.push({
				path: "/message/addannouncement",
				query: { id: row.id, type: "edit" },
			});
		},
		toDelete(row) {
			this.$confirm("此操作将删除该条公告, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					destroynotice({ id: row.id, types: 1, status: 0 }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
		disInformation(row) {
			let status = row.status;
			status == 1 ? (status = 0) : (status = 1);
			destroynotice({ id: row.id, types: 2, status: status }).then((res) => {
				if (res.code == 200) {
					console.log(this.$parent);
					this.$parent.$parent.getDataList();
				}
			});
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
