<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			id="exportTab"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			height="60vh"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>
			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="资讯标题" show-overflow-tooltip>
			</el-table-column>
			<el-table-column label="文章类型" width="80px">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 1">文章</span>
					<span v-if="scope.row.type == 2">快讯</span>
					<!-- <span v-if="scope.row.type == 3">日历</span>
					<span v-if="scope.row.type == 4">产业</span> -->
					<span v-if="scope.row.type == 5">专栏</span>
					<span v-if="scope.row.type == 7">活动</span>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="label_name" label="标签" min-width="15%">
			</el-table-column> -->
			<el-table-column prop="category_name" label="分类" width="60px">
			</el-table-column>
			<el-table-column prop="nickname" label="作者" width="140px">
			</el-table-column>
			<el-table-column prop="visits_num" label="阅读量" width="60px">
			</el-table-column>
			<el-table-column prop="release_time" label="发布时间" width="150px">
			</el-table-column>
			<el-table-column label="预览" width="120px">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="showPreView(scope.row)">
						预览
					</span>
					<span class="pointer margin_left_10" @click="copyUrl(scope.row)">
						复制链接
					</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200px">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="toEdit(scope.row)">
						修改
					</span>
					<span
						class="pointer margin_left_10"
						@click="recoverInformation(scope.row)"
					>
						恢复数据
					</span>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import {
	disInformation,
	delInformation,
	informationHome,
	addInformation,
	recoverInformation,
} from "@/api/article";
import { addPush } from "@/api/user";
import clipboard from "clipboard";
import ChineseConv from "chinese-s2t";

export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		toDetail(row) {
			this.$router.push({
				path: "/message/addmessage",
				query: { id: row.id, type: "detail" },
			});
		},
		toEdit(row) {
			this.$router.push({
				path: "/message/addmessage",
				query: { id: row.id, type: "edit" },
			});
		},

		showPreView(row) {
			let url = "https://metaera.com.hk/gw_detail?id=" + row.id;
			if (row.type == 2) {
				url = "https://metaera.com.hk/kuaixun_wz?id=" + row.id;
			}
			window.open(url, "预览");
		},
		copyUrl(row) {
			let msg = "https://metaera.com.hk/gw_detail?id=" + row.id;
			if (row.type == 2) {
				msg = "https://metaera.com.hk/kuaixun_wz?id=" + row.id;
			}
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		recoverInformation(row) {
			this.$confirm("此操作将恢复该条资讯, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					recoverInformation({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "恢复成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消恢复",
					});
				});
		},
	},
};
</script>

<style scoped></style>
