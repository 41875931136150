<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>SIDE活动管理/具体主活动添加side活动</span>
			</div>
			<div>
				<el-form
					:inline="true"
					:model="searchInfo"
					class="demo-form-inline margin_top_20 margin_left_20"
				>
					<el-form-item label="名称">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.key"
							placeholder=""
						>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary">
							上傳活動excel列表
							<input
								type="file"
								class="file-input"
								@change="handleFileUpload"
								accept=".xlsx, .xls"
							/>
						</el-button>
						<el-button type="primary" @click="addSide(1)"> 確定送出 </el-button>
						<el-button type="primary" @click="addSide(0)"> 取消关联 </el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData" ref="childRef"></tablec>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./otherSideTablec.vue";
import * as XLSX from "xlsx";

import { addOtherSideEvent, otherSideEventList } from "@/api/article";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	components: { tablec },
	mixins: [searchMixin],
	name: "",
	data() {
		return {
			title: "活动新增",
			tableData: [],
			tableHeaders: [], // 表头数据
			total: 0,
			formData: {},
			formLabelWidth: "120px",
			searchInfo: {
				page: 1,
				size: 200,
				mainEventId: this.$route.query.id,
				key: "",
				mainEventStatus: "",
			},
		};
	},
	created() {
		this.getDataList();
	},
	methods: {
		handleFileUpload(event) {
			const file = event.target.files[0];
			if (!file) {
				alert("请选择一个文件！");
				return;
			}

			// 创建 FileReader 对象来读取文件
			const reader = new FileReader();

			// 监听文件读取完成事件
			reader.onload = (e) => {
				const data = e.target.result;

				// 使用 XLSX 解析 Excel 数据
				const workbook = XLSX.read(data, { type: "binary" });

				// 获取第一个工作表
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];

				// 将工作表数据转换为 JSON
				const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

				// 将解析后的数据填充到表格
				if (jsonData.length) {
					this.tableHeaders = jsonData[0]; // 第一行作为表头
					const rows = jsonData.slice(1); // 其余作为表格内容

					// 将每行数据转换为对象，键为表头，值为对应数据
					this.tableData = rows.map((row) => {
						const rowObject = {};
						this.tableHeaders.forEach((header, index) => {
							console.log(header + "::" + row[index]);

							// 判断是否是日期时间字段
							if (
								typeof row[index] === "number" &&
								header.toLowerCase().includes("time")
							) {
								// 如果字段是数字并且表头包含 "time"，解析为日期
								rowObject[header] = this.parseExcelDate(row[index]);
							} else if (
								typeof row[index] === "number" &&
								header.toLowerCase().includes("date")
							) {
								// 如果字段是数字并且表头包含 "date"，解析为日期
								rowObject[header] = this.parseExcelDate(row[index]);
							} else {
								// 非日期字段，直接赋值
								rowObject[header] = row[index] || ""; // 防止空值
							}
						});
						return rowObject;
					});
					console.log("tableData:" + this.tableData);
				} else {
					alert("Excel 文件为空！");
				}
			};

			// 将文件读取为二进制字符串
			reader.readAsBinaryString(file);
		},
		parseExcelDate(excelDate) {
			const date = new Date((excelDate - 25569) * 86400 * 1000); // 将 Excel 日期序列号转换为 JS 时间戳
			return date.toLocaleString(); // 转换为本地日期时间字符串
		},
		addSide(add) {
			const childComponent = this.$refs.childRef;
			let arr = [];
			for (let index = 0; index < this.tableData.length; index++) {
				const element = this.tableData[index];
				arr.push(element);
			}

			if (arr.length == 0) {
				this.$message.error("请选择活动");
				return;
			}

			addOtherSideEvent({
				list: arr,
				type: add,
				sideevent: this.$route.query.id,
			})
				.then((res) => {
					if (res.code == 200) {
						this.getDataList();
						this.$message({
							type: "info",
							message: "已完成添加",
						});
					} else {
						this.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		getDataList() {
			let obj = {
				sideevent: this.$route.query.id,
			};

			otherSideEventList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.tableData = res.data;
						this.total = this.tableData.length;
					} else {
						this.tableData = [];
						this.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.file-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 150px;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}
</style>
