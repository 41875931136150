<template>
    <div class="addLabelCategoryDialog publicDialogTop">
        <el-dialog :title="title" :visible.sync="dialog" :modal-append-to-body='false'>
            <div class="folder">
                <el-form label-position="right" label-width="120px" :model="formData">
                    <el-form-item label="类别名称：" class="margin_top_20">
                        <el-input v-model="formData.name" class="custom_input_350"></el-input>
                    </el-form-item>
                    <el-form-item label="备注：" class="margin_top_20">
                        <el-input v-model="formData.notes" class="custom_input_350"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">

                <el-button type="primary" class="saveBtn" @click="submit">新增/修改</el-button>
                <el-button @click="dialog = false" class="cancel">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
        import { addCategory } from "@/api/article";
    export default {
        name: "",
        props: ['title','type'],
        data() {
            return {
                dialog: false, //文件夹弹框
                formData: {
                    name: "",
                    notes: "",
                    type: 2
                }
            };
        },
        methods:{
            submit() {
                const that = this;
                addCategory(that.formData).then((res) => {
                    if (res.code == 200) {
                        that.dialog=false
                        if(that.type=="edit"){
                            that.$parent.$parent.$parent.getDataList()

                        }else{

                            that.$parent.getDataList()
                        }
                    } else {
                        that.$message.error(res.message);
                    }
                }).catch((error) => {
                    that.$message.error(error.message);
                });
            },
        }
    };
</script>

<style scoped>
    .folder {
        margin-top: 86px;
        margin-bottom: 85px;
    }

    .folder .el-input {
        width: 532px;
        margin-left: -10px;
        height: 60px;
        background: #ffffff;
        opacity: 1;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
    }

    .folder .el-textarea {
        width: 532px;
        background: #ffffff;
        opacity: 1;
    }

    .saveBtn {
        width: 200px;
        height: 48px;
        background: #5681FF;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        font-size: 22px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
    }

    .cancel {
        width: 200px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #999999;
        margin-left: 80px;
        font-size: 22px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
    }
</style>
<style>
    .addLabelCategoryDialog .el-dialog {
        width: 600px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.29);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
    }

    .addLabelCategoryDialog .el-dialog__title {
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        margin-left: 30px;
    }

    .addLabelCategoryDialog .el-dialog__close::before {
        font-size: 18px;
        color: #000;
    }

    .addLabelCategoryDialog .el-dialog__header {
        border-radius: 10px 10px 0px 0px;
        padding: 0;
        width: 100%;
        height: 71px;
        display: flex;
        align-items: center;
        border: 1px solid #e0e0e0;
    }

    .addLabelCategoryDialog .el-dialog__headerbtn {
        top: initial;
        right: 40px;
    }

    .addLabelCategoryDialog .el-dialog__footer {
        padding: 0;
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
    }

    .addLabelCategoryDialog .el-dialog__body {
        padding: 0 40px;
    }

 
</style>