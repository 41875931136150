<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="name" label="类别名称" width="120">
			</el-table-column>
			<el-table-column
				prop="get_information_info_count"
				label="资讯数量"
				width="120"
			>
			</el-table-column>
			<el-table-column label="类型" width="120">
				<template slot-scope="scope"> 资讯 </template>
			</el-table-column>
			<el-table-column prop="notes" label="备注" show-overflow-tooltip>
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="toEdit(scope.row)"
						>编辑</span
					>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)"
						>删除</span
					>
				</template>
			</el-table-column>
		</el-table>
		<addCategory ref="editCategory" :title="title" type="edit"></addCategory>
	</div>
</template>

<script>
import addCategory from "./addCategory.vue";

import { delCategory } from "@/api/article";

export default {
	props: ["tableData"],
	components: { addCategory },
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		toEdit(row) {
			this.$refs.editCategory.dialog = true;
			this.title = "编辑类别";
			for (let key in row) {
				this.$refs.editCategory.formData[key] = row[key];
			}
		},
		toDelete(row) {
			this.$confirm("此操作将删除该条分类, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					delCategory({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
