<template>
	<div class="user flex main other_main">
		<asiden :asideData="asideList"></asiden>
		<div class="main_r">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import asiden from "../layout/aside/aside.vue";
export default {
	components: { asiden },
	name: "",
	data() {
		return {
			asideList: [
				{
					title: "活动管理",
					icon: "",
					router: "/event/events",
				},
				{
					title: "活动合集管理",
					icon: "",
					router: "/event/sideEvent",
				},
				{
					title: "订单管理",
					icon: "",
					router: "/event/order",
				},
			],
		};
	},
};
</script>

<style scoped></style>
