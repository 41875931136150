<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<!-- <el-table-column type="selection" width="55"> </el-table-column> -->

			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="活动名称"> </el-table-column>
			<el-table-column prop="address" label="活动地点"> </el-table-column>

			<el-table-column prop="start_time" label="活动开始时间">
			</el-table-column>
			<el-table-column prop="end_time" label="活动结束时间"></el-table-column>
			<el-table-column prop="host" label="主办方"></el-table-column>
			<el-table-column prop="url" label="活动链接"></el-table-column>
		</el-table>
	</div>
</template>

<script>
import { recommendactivity } from "@/api/article";
import { status } from "nprogress";

export default {
	props: ["tableData"],
	components: {},
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		editSide() {},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
