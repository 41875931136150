<template>
	<div class="main">
		<div class="main_l">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-card class="box_card">
						<div class="flex align_items_c justify_content_sb">
							<span>浏览量</span>
							<i class="el-icon-warning-outline"></i>
						</div>
						<h1>1365476856</h1>
						<div class="card_footer">
							上年同比
							<i class="el-icon-caret-top"></i>
							12% 上月同比
							<i class="el-icon-caret-bottom"></i>
							12%
						</div>
					</el-card>
				</el-col>
				<el-col :span="6">
					<el-card class="box_card">
						<div class="flex align_items_c justify_content_sb">
							<span>注册数量</span>
							<i class="el-icon-warning-outline"></i>
						</div>
						<h1>1365476856</h1>
					</el-card>
				</el-col>
				<el-col :span="6">
					<el-card class="box_card">
						<div class="flex align_items_c justify_content_sb">
							<span>认证数量</span>
							<i class="el-icon-warning-outline"></i>
						</div>
						<h1>1365476856</h1>
					</el-card>
				</el-col>
				<el-col :span="6">
					<el-card class="box_card">
						<div class="flex align_items_c justify_content_sb">
							<span>资讯量</span>
							<i class="el-icon-warning-outline"></i>
						</div>
						<h1>1365476856</h1>
					</el-card>
				</el-col>
			</el-row>
			<el-card class="card_box">
				<div slot="header" class="card_title">
					<span>今日数据</span>
				</div>
				<div>
					<div class="divider flex justify_content_sb align_items_c">
						<div class="flex align_items_c justify_content_c direction_column">
							<span>浏览量</span>
							<h1>124657</h1>
						</div>
						<el-divider direction="vertical"></el-divider>
						<div class="flex align_items_c justify_content_c direction_column">
							<span>浏览量</span>
							<h1>124657</h1>
						</div>
						<el-divider direction="vertical"></el-divider>
						<div class="flex align_items_c justify_content_c direction_column">
							<span>浏览量</span>
							<h1>124657</h1>
						</div>
					</div>
					<div class="divider flex justify_content_sb align_items_c">
						<div class="flex align_items_c justify_content_c direction_column">
							<span>浏览量</span>
							<h1>124657</h1>
						</div>
						<el-divider direction="vertical"></el-divider>
						<div class="flex align_items_c justify_content_c direction_column">
							<span>浏览量</span>
							<h1>124657</h1>
						</div>
						<el-divider direction="vertical"></el-divider>
						<div class="flex align_items_c justify_content_c direction_column">
							<span>浏览量</span>
							<h1>124657</h1>
						</div>
					</div>
				</div>
			</el-card>
			<el-card class="card_box">
				<div slot="header" class="card_title">
					<span>收益同期数据对比</span>
				</div>
				<div class="heatechart">
					<span>单位：万</span>
					<chart
						:width="'100%'"
						id="dataTrendchartDet"
						:height="'100%'"
						:option="option"
					></chart>
				</div>
			</el-card>
		</div>
		<div class="main_r">
			<el-card class="card_box likes_num">
				<div slot="header" class="card_title">
					<span>发帖赞数</span>
				</div>
				<div class="likes_num_title flex align_items_c">
					<span>排名</span>
					<span>账户名称</span>
					<span>获赞数量</span>
					<span>得分</span>
				</div>
				<div class="likes_num_title_item flex align_items_c">
					<div class="item_num flex align_items_c justify_content_c">1</div>
					<div class="item_name ellipsis_one"> 小亮小亮 </div>
					<div class="item_number ellipsis_one"> 565656666766666666666 </div>
					<div class="item_mark">
						<el-progress :percentage="95" :format="format"></el-progress>
					</div>
				</div>
				<div class="likes_num_title_item flex align_items_c">
					<div class="item_num flex align_items_c justify_content_c">2</div>
					<div class="item_name ellipsis_one"> 小亮 </div>
					<div class="item_number ellipsis_one"> 5656566667 </div>
					<div class="item_mark">
						<el-progress :percentage="85" :format="format"></el-progress>
					</div>
				</div>
				<div class="likes_num_title_item flex align_items_c">
					<div class="item_num flex align_items_c justify_content_c">2</div>
					<div class="item_name ellipsis_one"> 小亮 </div>
					<div class="item_number ellipsis_one"> 5656566667 </div>
					<div class="item_mark">
						<el-progress :percentage="78" :format="format"></el-progress>
					</div>
				</div>
				<div class="likes_num_title_item flex align_items_c">
					<div class="item_num flex align_items_c justify_content_c">2</div>
					<div class="item_name ellipsis_one"> 小亮 </div>
					<div class="item_number ellipsis_one"> 5656566667 </div>
					<div class="item_mark">
						<el-progress :percentage="66" :format="format"></el-progress>
					</div>
				</div>
				<div class="likes_num_title_item flex align_items_c">
					<div class="item_num flex align_items_c justify_content_c">2</div>
					<div class="item_name ellipsis_one"> 小亮 </div>
					<div class="item_number ellipsis_one"> 5656566667 </div>
					<div class="item_mark">
						<el-progress :percentage="60" :format="format"></el-progress>
					</div>
				</div>
				<div class="likes_num_title_item flex align_items_c">
					<div class="item_num flex align_items_c justify_content_c">2</div>
					<div class="item_name ellipsis_one"> 小亮 </div>
					<div class="item_number ellipsis_one"> 5656566667 </div>
					<div class="item_mark">
						<el-progress :percentage="90" :format="format"></el-progress>
					</div>
				</div>
			</el-card>
			<el-card class="card_box">
				<div slot="header" class="card_title">
					<span>热点事项</span>
				</div>
				<div class="heat_list hidden_scroll">
					<div
						class="heat_item flex align_items_c justify_content_sb"
						v-for="item in 8"
					>
						<div class="heat_item_l flex align_items_c">
							<img src="../../assets/images/h.png" alt="" />
							<h2>1</h2>
							<span class="ellipsis_one">比特币矿企提交其破产性保护措施</span>
						</div>
						<div>
							<span>浏览量</span>
							<span>53133</span>
						</div>
					</div>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
import chart from "@/components/echart.vue";
import { getHomeData } from "@/api/article";
export default {
	components: {
		chart,
	},
	name: "home",
	data() {
		return {
			option: {
				legend: {},
				tooltip: {},
				dataset: {
					source: [
						["product", "2022", "2023"],
						["1月", 4, 7],
						["2月", 8, 7],
						["3月", 8, 6],
						["4月", 3, 7],
						["5月", 4, 9],
						["6月", 2, 6],
						["7月", 5, 8],
						["8月", 6, 3],
					],
				},
				xAxis: { type: "category" },
				yAxis: {},
				// Declare several bar series, each will be mapped
				// to a column of dataset.source by default.
				series: [{ type: "bar" }, { type: "bar" }],
			},
		};
	},
	created: {},
	methods: {
		format(percentage) {
			return percentage === 100 ? "满" : `${percentage}分`;
		},
		getHomeData() {
			getHomeData({}).then((res) => {
				if (res.code == 200) {
				}
			});
		},
	},
};
</script>

<style scoped>
.main {
	width: 90%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.main_l {
	width: 65%;
}

.main_r {
	width: calc(35% - 20px);
}

.box_card {
	height: 140px;
	position: relative;
}

.box_card span,
.box_card i {
	font-size: 14px;
	color: rgb(172, 168, 168);
}

.box_card h1 {
	font-size: 25px;
	margin-top: 5px;
}

.card_footer {
	position: absolute;
	bottom: 10px;
}

.card_box {
	margin-top: 20px;
}

.divider {
	margin: 0 auto;
	margin-top: 40px;
	width: 70%;
}

.divider:nth-child(1) {
	margin-top: 20px;
}

.divider span {
	display: inline-block;
}

.divider .flex span {
	color: rgb(172, 168, 168);
}

.divider .flex h1 {
	margin-top: 5px;
	font-size: 25px;
}

.heatechart {
	width: 100%;
	height: 400px;
	margin-top: 0px;
	position: relative;
}

.heatechart span {
	position: absolute;
	right: 30px;
	top: 0;
}

.likes_num {
	margin-top: 0;
}

.likes_num_title {
	color: #000;
	font-size: 14px;
}

.likes_num_title span:nth-child(1) {
	margin-left: 0;
}

.likes_num_title span {
	margin-left: 50px;
}

.likes_num_title span:nth-last-child(1) {
	margin-left: 80px;
}

.likes_num_title_item {
	margin-top: 20px;
}

.item_num {
	width: 25px;
	height: 20px;
	background-color: #e47878;
	color: #fff;
}

.item_name {
	color: #000;
	width: 50px;
	margin-left: 50px;
}

.item_number {
	width: 70px;
	color: #000;
	margin-left: 50px;
}

.item_mark {
	margin-left: 60px;
	width: 40%;
}

.heat_list {
	height: 475px;
	overflow-y: hidden;
}

.heat_item {
	margin-top: 20px;
}
.heat_item_l img {
	width: 40px;
}
.heat_item_l h2,
.heat_item_l span {
	margin-left: 10px;
}
.heat_item_l span {
	display: inline-block;
	width: 250px;
}
.heat_item_l h2,
.heat_item span {
	font-size: 15px;
}
</style>
<style>
.item_mark .el-progress__text {
	font-size: 12px;
}
</style>
