<template>
	<div class="other_page_r other_page_r_2">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>活动管理/新增活动管理</span>
			</div>
			<div>
				<el-form
					:inline="true"
					:model="searchInfo"
					class="demo-form-inline margin_top_20 margin_left_20"
				>
					<el-form-item label="查询活动名称并新增关联">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.key"
							placeholder=""
						></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div
				class="table_box"
				v-if="tableSearchData && tableSearchData.length > 0"
			>
				<tablec :tableData="tableSearchData"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="totalSearch > 0"
						:total="totalSearch"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getSearchDataList"
					/>
				</div>
			</div>
			<div
				v-if="tableSearchData && tableSearchData.length > 0"
				style="height: 30px"
			></div>
			<div class="sideevent_section">
				<div class="sideevent_section_title">现有活动合集</div>
				<div
					class="sideevent_section_status"
					:class="{
						sideevent_section_status_active: searchMainInfo.status == 0,
					}"
					@click="statusClick(0)"
					>进行中</div
				>
				<div class="sideevent_section_status">|</div>
				<div
					class="sideevent_section_status"
					:class="{
						sideevent_section_status_active: searchMainInfo.status == 1,
					}"
					@click="statusClick(1)"
					>已结束</div
				>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData" :type="'edit'"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchMainInfo.page"
						:limit.sync="searchMainInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
			<div style="height: 20px"></div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import {
	mainLists,
	activitylists,
	detailsactivity,
	recommendactivity,
} from "@/api/article";
import { searchMixin } from "@/utils/searchMixin.js";
import { sideevent } from "../../../api/article";
export default {
	components: { tablec },
	mixins: [searchMixin],
	name: "",
	data() {
		return {
			title: "活动新增",
			tableData: [],
			total: 0,
			tableSearchData: [],
			totalSearch: 0,
			formLabelWidth: "120px",
			searchInfo: {
				page: 1,
				size: 10,
				key: "",
			},
			searchMainInfo: {
				page: 1,
				size: 10,
				is_main: 1,
				key: "",
				status: 0,
			},
		};
	},
	created() {
		this.getDataList();
	},
	methods: {
		statusClick(status) {
			this.searchMainInfo.status = status;
			this.getDataList();
		},
		reset() {
			this.searchInfo.key = "";
			this.searchInfo.page = 1;
			this.getSearchDataList();
		},

		searchBtn() {
			this.searchInfo.page = 1;
			this.getSearchDataList();
		},

		getDataList() {
			mainLists(this.searchMainInfo)
				.then((res) => {
					if (res.code == 200) {
						console.log(res.data.total);

						this.tableData = res.data.list;
						this.total = res.data.total;
					} else {
						this.tableData = [];
						this.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},

		getSearchDataList() {
			if (this.searchInfo.key == "") {
				this.tableSearchData = [];
				this.totalSearch = 0;
				return;
			} else {
			}
			activitylists(this.searchInfo)
				.then((res) => {
					if (res.code == 200) {
						this.tableSearchData = res.data.list;
						this.totalSearch = res.data.total;
					} else {
						this.tableSearchData = [];
						this.totalSearch = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.other_page_r_2 {
	height: auto !important;
}

.sideevent_section {
	margin-left: 20px;
	display: flex;
	align-content: center;
	align-items: center;
}

.sideevent_section_title {
	font-size: 30px;
}

.sideevent_section_status {
	margin-left: 15px;
	font-size: 20px;
}

.sideevent_section_status_active {
	color: aqua;
}
</style>
