<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>SIDE活动管理/具体主活动添加side活动</span>
			</div>
			<div>
				<el-form
					:inline="true"
					:model="searchInfo"
					class="demo-form-inline margin_top_20 margin_left_20"
				>
					<el-form-item label="名称">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.key"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="筛选关联状态" prop="type">
						<el-select v-model="searchInfo.mainEventStatus" placeholder="">
							<el-option label="已关联活动" value="1"> </el-option>
							<el-option label="未关联活动" value="2"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
						<el-button type="primary" @click="addSide(1)">
							添加side活动关联
						</el-button>
						<el-button type="primary" @click="addSide(0)">
							取消side活动关联
						</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData" ref="childRef"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./sideTablec.vue";
import {
	activitylists,
	sideevent,
	detailsactivity,
	recommendactivity,
} from "@/api/article";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	components: { tablec },
	mixins: [searchMixin],
	name: "",
	data() {
		return {
			title: "活动新增",
			tableData: [],
			total: 0,
			formData: {},
			formLabelWidth: "120px",
			searchInfo: {
				page: 1,
				size: 10,
				mainEventId: this.$route.query.id,
				key: "",
				mainEventStatus: "",
			},
		};
	},
	created() {
		this.getDataList();
	},
	methods: {
		addSide(add) {
			// 通过 this.$refs.childRef 访问子组件实例
			const childComponent = this.$refs.childRef;
			if (childComponent) {
				const selectedRows = childComponent.$refs.multipleTable.selection;
				console.log(selectedRows);
				let arr = [];
				for (let index = 0; index < selectedRows.length; index++) {
					const element = selectedRows[index];
					arr.push(element.id);
				}
				if (arr.length == 0) {
					this.$message.error("请选择活动");
					return;
				}
				sideevent({
					ids: arr,
					add: add,
					mainEventId: this.$route.query.id,
				})
					.then((res) => {
						if (res.code == 200) {
							console.log(res.data.total);
							this.getDataList();
						} else {
							this.total = 0;
						}
						this.loading = false;
					})
					.catch((error) => {
						this.$message.error(error.message);
					});
			}
		},
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},

		getDataList() {
			let obj = {
				page: this.searchInfo.page,
				size: this.searchInfo.size,
				mainEventId: this.$route.query.id,
				mainEventStatus: this.searchInfo.mainEventStatus,
				key: this.searchInfo.key,
			};
			console.log(this.searchInfo.status);
			console.log(this.$route.query.id);

			activitylists(obj)
				.then((res) => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.total = res.data.total;
					} else {
						this.tableData = [];
						this.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped></style>
