<template>
    <div class="other_page_r">
        <el-card class="card_box">
            <div slot="header" class="card_title">
                <span>资讯管理公告</span>
            </div>
            <div>
                <!-- <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
                    <el-form-item label="公告名称">
                        <el-input class="custom_input_170" v-model="searchInfo.user" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="标签">
                        <el-input class="custom_input_170" v-model="searchInfo.user" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                        <el-button type="primary" @click="onSubmit">重置</el-button>
                    </el-form-item>
                </el-form> -->
        
            </div>
            <div class="flex justify_content_end custom_btn">
                <el-button @click="$router.push({path:'/message/addannouncement'})">新增公告</el-button>
            </div>
            <div class="table_box">
                <tablec :tableData="tableData"></tablec>
            </div>
            <div class="flex justify_content_end aligin_items_c table_footer custom_btn">
                <div>

                    <pagination
                    v-show="total > 0"
                    :total="total"
                    :page.sync="searchInfo.page"
                    :limit.sync="searchInfo.size"
                    @pagination="getDataList"
                  />

                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import tablec from "./tablec.vue"
    import { noticeLists } from "@/api/article";
    export default {
        components: {  tablec},
        name: '',
        data() {
            return {
                tableData:[],
                total: Number,
                searchInfo: {
                    page: 1,
                    size: 10,
                },
              
            }
        },
        created() {
            this.getDataList()
        },
        methods: {

            getDataList() {
                noticeLists(this.searchInfo).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.list
                        this.total = res.data.total;
                    } else {

                        this.tableData = []
                        this.total = 0;
                        
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.$message.error(error.message);
                });
            }
        }
  
    }
</script>

<style scoped>

</style>