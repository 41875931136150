<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>资讯管理/导航工具管理</span>
			</div>
			<div>
				<el-form :inline="true" :model="searchInfo" class="demo-form-inline">
					<el-form-item label="工具名称">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.title"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="分类">
						<el-select
							class="custom_input_170"
							v-model="searchInfo.category_id"
							placeholder=""
						>
							<el-option
								:label="item.name"
								:value="item.id"
								v-for="(item, index) in categoryList"
								:key="index"
							></el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
						<el-button type="primary" @click="toAdd">添加工具</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import ExportExcel from "../../../components/ExportExcel.vue";
import {
	getInformationList,
	getCategoryList,
	getLabelList,
} from "@/api/article";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	mixins: [searchMixin],
	components: { tablec, ExportExcel },
	name: "",
	data() {
		return {
			tableData: [],
			total: Number,
			categoryList: [],
			labelList: [],
			searchInfo: {
				title: "",
				category_id: "",
				type: "6",
				page: 1,
				size: 10,
			},
		};
	},
	created() {
		this.getDataList();
		this.getCategoryListFun();
	},
	methods: {
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},
		getCategoryListFun() {
			getCategoryList({ type: 6 })
				.then((res) => {
					if (res.code == 200) {
						this.categoryList = res.data.data;
					} else {
						this.categoryList = [];
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		toAdd(row) {
			this.$router.push({
				path: "/message/addtools",
				query: {},
			});
		},
		getDataList() {
			const that = this;
			this.searchInfo.type = "6";
			getInformationList(this.searchInfo)
				.then((res) => {
					console.log(res);
					if (res.code == 200) {
						that.tableData = res.data.data;
						that.total = res.data.total;
					} else {
						that.tableData = [];
						that.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped></style>
