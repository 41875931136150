<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="company" label="公司名称"> </el-table-column>
			<el-table-column prop="symbol" label="股票代码"> </el-table-column>
			<el-table-column prop="country" label="国家"></el-table-column>
			<el-table-column prop="count" label="持有比特币数量"></el-table-column>
			<!-- 占比列，使用 Scoped Slot -->
			<el-table-column prop="count" label="占比">
				<template #default="{ row }">
					{{ ((row.count / 21000000) * 100).toFixed(6) }}%
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="更新时间"> </el-table-column>
			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="edit(scope.row)"
						>修改</span
					>
					<span class="pointer margin_left_10" @click="del(scope.row)"
						>删除</span
					>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { dataeraList, dataeraUpdate } from "@/api/data";
import { status } from "nprogress";

export default {
	props: ["tableData"],
	components: {},
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		edit(row) {
			this.$parent.$parent.edit(row);
		},
		del(row) {
			this.$confirm("此操作将将删除该条数据, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					var obj = {
						company: row.company,
						symbol: row.symbol,
						country: row.country,
						count: row.count,
						is_delete: 1,
						id: row.id,
					};
					dataeraUpdate(obj).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
