<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>数据管理/BTC持币机构</span>
			</div>
			<div>
				<el-form
					:inline="true"
					:model="searchInfo"
					class="demo-form-inline margin_top_20 margin_left_20"
				>
					<el-form-item label="名称">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.company"
							placeholder=""
						></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
						<el-button type="primary" @click="add">添加数据</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
		<el-dialog title="添加公司数据" :visible.sync="dialogFormVisible">
			<el-form :model="form">
				<el-form-item label="公司名称：" :label-width="formLabelWidth">
					<el-input v-model="form.company" autocomplete="off"> </el-input>
				</el-form-item>
				<el-form-item label="股票代码：" :label-width="formLabelWidth">
					<el-input v-model="form.symbol" autocomplete="off"> </el-input>
				</el-form-item>
				<el-form-item label="国家：" :label-width="formLabelWidth">
					<el-input v-model="form.country" autocomplete="off"> </el-input>
				</el-form-item>
				<el-form-item label="持币数量：" :label-width="formLabelWidth">
					<el-input v-model="form.count" type="number" autocomplete="off">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="addData"> 确 定 </el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import { dataeraList, dataeraUpdate } from "@/api/data";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	components: { tablec },
	mixins: [searchMixin],
	name: "",
	data() {
		return {
			title: "数据管理",
			tableData: [],
			total: 0,
			dialogFormVisible: false,
			form: {
				company: "",
				symbol: "",
				country: "",
				count: "",
				id: "",
			},
			formLabelWidth: "120px",
			searchInfo: {
				page: 1,
				size: 1000,
				company: "",
			},
		};
	},
	created() {
		this.getDataList();
	},
	methods: {
		add() {
			this.dialogFormVisible = true;
			this.form = {
				company: "",
				symbol: "",
				country: "",
				count: "",
				id: "",
			};
		},
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},
		edit(val) {
			this.dialogFormVisible = true;
			this.form = {
				company: val.company,
				symbol: val.symbol,
				country: val.country,
				count: val.count,
				id: val.id,
			};
		},
		addData() {
			this.dialogFormVisible = false;
			var obj = {
				company: this.form.company,
				symbol: this.form.symbol,
				country: this.form.country,
				count: this.form.count,
				is_delete: 0,
			};
			if (this.form.id) {
				obj.id = this.form.id;
			}
			dataeraUpdate(obj)
				.then((res) => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "数据处理成功!",
						});
						this.getDataList();
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},

		getDataList() {
			dataeraList(this.searchInfo)
				.then((res) => {
					if (res.code == 200) {
						console.log(res.data.total);

						this.tableData = res.data.list;
						this.total = res.data.total;
					} else {
						this.tableData = [];
						this.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped></style>

<style>
.v-modal {
	z-index: -10 !important;
}
</style>
